// i18next-extract-mark-ns-start partners
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';

import {ContactSalesButton} from 'components/ContactSalesButton';

import index1 from 'images/payment_gateway.svg';

// Clients
import {AnchorLink} from 'components/AnchorLink';
import {IndexBackground} from 'components/landings/IndexBackground';
import {Flex} from 'components/Flex';
import redsys from 'images/redsys.svg';
import aws from 'images/aws.png';
import visa from 'images/visa-logo-small.svg';
import masterCard from 'images/mastercard-logo-small.svg';
import salesforce from 'images/salesforce_logo.svg';
import shopify from 'images/shopify_payment_gateway_logo.svg';
import woocommerce from 'images/woocommerce_logo_vertical.png';
import wix from 'images/wix_black_logo.svg';
import magento from 'images/magento_logo_vertical.png';
import prestashop from 'images/prestashop_logo_vertical.png';
import pci_security_standards_council from 'images/pci_security_standards_council.png';
import european_payments_council from 'images/european_payments_council.png';
import banco_de_es_logo from 'images/banco_de_es_logo.svg';
import credit_andorra from 'images/credit_andorra_payment_gateway.svg';
import bbva from 'images/bbva.png';
import sabadell from 'images/sabadell.png';
import caixa_bank from 'images/caixa_bank.png';
import bizum_logo_small from 'images/bizum-logo-small.svg';
import spreedly_logo from 'images/spreedly_logo.svg';
import glop from 'images/glop_logo.svg';
import yumminn from 'images/yumminn_logo.png';
import nuveapp from 'images/logo-nuve-app.png';
import qamarero from 'images/qamarero_logo.png';
import honei from 'images/honei_logo.png';
import paymefy from 'images/paymefy-logo-dark.png';
import dea_logo from 'images/dea_logo.svg';
import revo from 'images/revo_logo.png';
import hotelgest from 'images/logo-hotelgest.png';
import Grid from 'components/Grid';
import {Card} from 'components/Card';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {StyledDiv, StyledLi} from 'components/StyledSx';
import {ScreenSizes} from '../../types/responsive';
import {InternalPageLink, RedsysAlternativeLink} from 'components/links/Pages';
import {BlogLink} from 'components/links/Blog';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const PartnerLogo = styled(StyledDiv)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${ScreenSizes.sm}) {
    margin: auto;
  }

  a {
    max-width: 100%;
    width: 100%;
    @media (max-width: ${ScreenSizes.sm}) {
      min-height: 80px;
    }
  }
  img {
    max-width: 100%;
  }
`;

type Institution = {
  logo: string;
  title: string;
  width: number;
  description: string | JSX.Element;
  url: string;
};

// Comparison function to sort by the 'title' property
function compareByTitle(a: Institution, b: Institution) {
  // Convert titles to lowercase to ensure case-insensitive comparison
  const titleA = a.title.toLowerCase();
  const titleB = b.title.toLowerCase();

  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }
  return 0; // Titles are equal
}

const PartnersPage: React.FC = () => {
  const {t} = useI18next();

  const publicInstitutions: Institution[] = [
    {
      logo: pci_security_standards_council,
      title: 'PCI Security Standards Council',
      width: 200,
      description: t(
        'MONEI is one of the participating organizations collaborating with the council on its mission to help' +
          ' secure payment data globally.'
      ),
      url: 'https://www.pcisecuritystandards.org/get_involved/participating_organizations/'
    },
    {
      logo: european_payments_council,
      title: 'European Payments Council',
      width: 200,
      description: (
        <Trans>
          MONEI is a member of the European Payments Council under the{' '}
          <InternalPageLink slug="payment-methods/sepa-request-to-pay">
            SEPA Request-to-Pay (SRTP)
          </InternalPageLink>{' '}
          scheme.
        </Trans>
      ),
      url: 'https://www.europeanpaymentscouncil.eu/news-insights/news/new-srtp-participant-0'
    },
    {
      logo: banco_de_es_logo,
      title: 'Banco de España',
      width: 200,
      description: t('MONEI is a Payments Institution regulated by the Bank of Spain.'),
      url: 'https://www.bde.es/webbde/es/estadis/psri/psri_es.html'
    },
    {
      logo: dea_logo,
      title: 'DEA (Digital Euro Association)',
      width: 200,
      description: t(
        'MONEI is an institutional member of the think tank specializing in central bank digital currencies, stablecoins and other forms of digital money.'
      ),
      url: 'https://home.digital-euro-association.de/partners'
    }
  ];

  const financialInstitutions: Institution[] = [
    {
      logo: credit_andorra,
      title: 'Crèdit Andorrà',
      width: 200,
      description: (
        <Trans>
          Through its agreement with MONEI, the bank helps Andorran businesses simplify their{' '}
          <Link to="/andorra-payment-gateway/">digital payments</Link> through an API connection.
        </Trans>
      ),
      url: 'https://www.creditandorragroup.com/ca/sala-de-comunicacio/actualitat/credit-andorra-firma-un-acord-amb-la-fintech-monei-facilitar-els'
    },
    {
      logo: bbva,
      title: 'BBVA',
      width: 200,
      description: t(`Partnering bank`),
      url: 'https://www.bbva.es/'
    },
    {
      logo: sabadell,
      title: 'Banc Sabadell',
      width: 200,
      description: t(`Partnering bank`),
      url: 'https://www.bancsabadell.com/'
    },
    {
      logo: caixa_bank,
      title: 'Caixabank',
      width: 200,
      description: t(`Partnering bank`),
      url: 'https://www.caixabank.es/'
    },

    {
      logo: masterCard,
      title: 'Mastercard',
      width: 180,
      description: t(`Principal Member`),
      url: 'https://www.mastercard.com/'
    },
    {
      logo: bizum_logo_small,
      title: 'Bizum',
      width: 180,
      description: t(
        'MONEI is the first platform to enable brick-and-mortar retailers to accept Bizum payments' +
          ' in-store.'
      ),
      url: 'https://bizum.es/blog/pagos-presenciales-bizum-comercios-fisicos/'
    },
    {
      logo: spreedly_logo,
      title: 'Spreedly',
      width: 200,
      description: t(
        'Our payment gateway is listed among 120+ of the most popular payment gateway integrations on' +
          ' Spreedly’s payments orchestration platform.'
      ),
      url: 'https://www.spreedly.com/connections'
    }
  ];

  const technologyPartners = [
    <RedsysAlternativeLink>
      <img src={redsys} alt="redsys" width={190} />
    </RedsysAlternativeLink>,
    <BlogLink slug="aws-startup-architecture-of-the-year-challenge-monei">
      <img src={aws} alt="aws" width={150} />
    </BlogLink>,
    <InternalPageLink slug="payment-methods/credit-cards">
      <img src={visa} alt="visa" width={180} />
    </InternalPageLink>,
    <InternalPageLink slug="payment-methods/credit-cards">
      <img src={masterCard} alt="master-card" width={165} />
    </InternalPageLink>,
    <AnchorLink href="https://docs.monei.com/docs/e-commerce/salesforce/">
      <img src={salesforce} alt="salesforce" width={165} />
    </AnchorLink>,
    <Link to="/shopify-payment-gateway/">
      <img src={shopify} alt="shopify" width={190} />
    </Link>,
    <Link to="/woocommerce-payment-gateway">
      <img src={woocommerce} alt="woocommerce" width={180} />
    </Link>,
    <Link to="/wix-payment-gateway/">
      <img src={wix} alt="wix" width={170} />
    </Link>,
    <Link to="/magento-payment-gateway/">
      <img src={magento} alt="magento" width={185} />
    </Link>,
    <Link to="/prestashop-payment-gateway/">
      <img src={prestashop} alt="prestashop" width={160} />
    </Link>
  ];

  const paymentIntegrationPartners: Institution[] = [
    {
      logo: yumminn,
      title: 'Yumminn',
      width: 120,
      description: (
        <Trans>
          We partner with Yumminn to help its restaurants accept more payment methods via QR codes
          and payment requests with <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink>.
        </Trans>
      ),
      url: 'https://yumminn.com/'
    },
    {
      logo: glop,
      title: 'Glop',
      width: 120,
      description: t(
        `We partner with Glop to help its platform users in the hospitality, fashion, retail, and food industries accept more payment methods.`
      ),
      url: 'https://www.glop.es/'
    },
    {
      logo: honei,
      title: 'Honei',
      width: 155,
      description: t(
        `We partner with Honei to help its app users/merchants accept more payment methods via QR codes and payment requests with MONEI Pay.`
      ),
      url: 'https://www.honei.app/'
    },
    {
      logo: qamarero,
      title: 'Qamarero',
      width: 175,
      description: t(
        `With MONEI Pay, Qamarero can help its restaurants accept more payment methods via QR codes and payment requests.`
      ),
      url: 'https://www.qamarero.com/'
    },
    {
      logo: nuveapp,
      title: 'Nuve',
      width: 140,
      description: t(
        `Nuve uses MONEI Pay to help its restaurants accept more payment methods via QR codes and payment requests.`
      ),
      url: 'https://thenuveapp.com/'
    },
    {
      logo: paymefy,
      title: 'Paymefy',
      width: 175,
      description: (
        <Trans>
          We partner with Paymefy to help make debt collection easier by offering a wider range of
          payment options including{' '}
          <InternalPageLink slug="payment-methods/credit-cards">credit card</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
          <InternalPageLink slug="payment-methods/click-to-pay">Click to Pay</InternalPageLink>, and{' '}
          <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>.
        </Trans>
      ),
      url: 'https://www.Paymefy.com/'
    },
    {
      logo: revo,
      title: 'REVO',
      width: 175,
      description: t(
        `We help Revo integrate more local and alternative payment methods (including Bizum) into its platform, with MONEI Connect. `
      ),
      url: 'https://revo.works/'
    },
    {
      logo: hotelgest,
      title: 'Hotelgest',
      width: 175,
      description: t(
        `Hotelgest uses MONEI as their default PSP so clients can pay online via credit card, Apple Pay, Google Pay, and Bizum for their bookings and services/products purchased at the hotel. `
      ),
      url: 'https://hotelgest.com/'
    }
  ];

  return (
    <>
      <SEO
        path="partners"
        title={t('Our Partners')}
        description={t(
          'MONEI partners with financial institutions, international regulatory bodies, and other fintech companies to provide the best and most secure financial services.'
        )}
        bodyAttributes={{class: 'selling-page'}}
      />
      <IndexBackground
        sx={{
          left: {all: '100%', xl: '110%'},
          top: {all: '-27%', xl: '-27%'},
          height: {all: '1728px', xl: '1728px'},
          width: {all: '1342px', xl: '1342px'},
          transform: {
            all: 'rotate(-61deg) skew(-4deg, 32deg)',
            xl: 'rotate(-61deg) skew(-4deg, 32deg)'
          },
          display: {lg: 'none'}
        }}>
        <Content>
          <Section sx={{margin: {all: '0 -30px', md: '0'}}}>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>MONEI Partners</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                MONEI partners with financial institutions, international regulatory bodies, and
                other fintech companies to provide the best and most secure financial services. We
                help omnichannel merchants navigate the online and physical payments ecosystem. And
                contribute to innovation in the financial technology sector.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MainTitleImg
              src={index1}
              sx={{display: {md: 'none'}, width: {lg: '470px'}}}
              alt="MONEI payment gateway"
              title="MONEI"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {all: '100px', xs: '0px'}}}>
        <Section sx={{margin: {all: '0 -30px', md: '0'}}}>
          <div>
            <SectionHeader underline>
              <Trans>Public & regulatory institutions and innovation</Trans>
            </SectionHeader>
            <Card sx={{textAlign: 'start', padding: '36px'}}>
              <List style={{listStyle: 'none'}}>
                {publicInstitutions.sort(compareByTitle).map((item) => (
                  <StyledLi sx={{marginBottom: {all: '24px', sm: '48px'}}}>
                    <Flex
                      gap={36}
                      sx={{
                        flexDirection: {sm: 'column'},
                        alignItems: {all: 'center', sm: 'start'}
                      }}>
                      <PartnerLogo>
                        <img src={item.logo} alt={item.title} width={item.width} />
                      </PartnerLogo>
                      <div style={{flex: 4}}>
                        <h4 style={{maxWidth: 'unset'}}>
                          <AnchorLink href={item.url}>{item.title}</AnchorLink>
                        </h4>
                        <p>{item.description}</p>
                      </div>
                    </Flex>
                  </StyledLi>
                ))}
              </List>
            </Card>
          </div>
        </Section>
      </Content>
      <Content>
        <Section>
          <div>
            <SectionHeader underline>
              <Trans>Financial institutions</Trans>
            </SectionHeader>
            <Card sx={{textAlign: 'start', padding: '36px'}}>
              <List style={{listStyle: 'none'}}>
                {financialInstitutions.sort(compareByTitle).map((item) => (
                  <StyledLi sx={{marginBottom: {all: '24px', sm: '56px'}}}>
                    <Flex
                      gap={36}
                      sx={{
                        flexDirection: {sm: 'column'},
                        alignItems: {all: 'center', sm: 'start'}
                      }}>
                      <PartnerLogo>
                        <img src={item.logo} alt={item.title} width={item.width} />
                      </PartnerLogo>
                      <div style={{flex: 4}}>
                        <h4 style={{maxWidth: 'unset'}}>
                          <AnchorLink href={item.url}>{item.title}</AnchorLink>
                        </h4>
                        <p>{item.description}</p>
                      </div>
                    </Flex>
                  </StyledLi>
                ))}
              </List>
            </Card>
          </div>
        </Section>
      </Content>
      <Content>
        <Section>
          <div>
            <SectionHeader underline>
              <Trans>
                Payment integration partners (
                <InternalPageLink slug="connect">MONEI Connect</InternalPageLink>)
              </Trans>
            </SectionHeader>
            <Card sx={{textAlign: 'start', padding: '36px'}}>
              <List style={{listStyle: 'none'}}>
                {paymentIntegrationPartners.sort(compareByTitle).map((item) => (
                  <StyledLi sx={{marginBottom: {all: '24px', sm: '56px'}}}>
                    <Flex
                      gap={36}
                      sx={{
                        flexDirection: {sm: 'column'},
                        alignItems: {all: 'center', sm: 'start'}
                      }}>
                      <PartnerLogo>
                        <img src={item.logo} alt={item.title} width={item.width} />
                      </PartnerLogo>
                      <div style={{flex: 4}}>
                        <h4 style={{maxWidth: 'unset'}}>
                          <AnchorLink href={item.url} external>
                            {item.title}
                          </AnchorLink>
                        </h4>
                        <p>{item.description}</p>
                      </div>
                    </Flex>
                  </StyledLi>
                ))}
              </List>
            </Card>
          </div>
        </Section>
      </Content>
      <Content>
        <Section sx={{margin: {all: '0 -30px', md: '0'}}}>
          <div>
            <SectionHeader underline>
              <Trans>Technology partners</Trans>
            </SectionHeader>
            <Card>
              <Grid
                gap={64}
                sx={{
                  gridTemplateColumns: {
                    all: 'repeat(5,1fr)',
                    sm: 'repeat(2,1fr)',
                    xs: 'repeat(1,1fr)'
                  }
                }}>
                {technologyPartners.map((partner) => (
                  <PartnerLogo>{partner}</PartnerLogo>
                ))}
              </Grid>
            </Card>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default PartnersPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "partners"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
